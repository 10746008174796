<template>
  <div class="name">
    <button class="btn btn-primary" @click="setTextVal">Set text</button>
    <EnForm
      ref="form"
      @submit="handleSubmit"
      :prevent-multi-submit="false"
      >

      <template #fields>

        <EnFormGroup label="Wysiwyg">
          <EnWysiwyg
            name="Wysiwyg"
            model-value=""></EnWysiwyg>
        </EnFormGroup>

      </template>
      <template #buttons>
        <button type="button" @click="submit">Submit</button>
      </template>
    </EnForm>
    <pre>
      {{ formData }}
    </pre>
  </div>
</template>

<script>
import EnWysiwyg from '../../src/lib-components/EnWysiwyg.vue';

export default {
  name: 'FileUploadView',
  data() {
    return {
      model: {
        textVal: "something",
      },
      formData: ''
    }
  },
  methods: {
    setTextVal() {
      this.model.textVal = "something else?"
    },
    handleSubmit(data) {
      this.formData = data
    },
    async submit() {
      const data = await this.$refs.form.submitForm()
      console.log(data.Wysiwyg)
    }
  }
}
</script>

<style lang="scss">
.name {

}
</style>
