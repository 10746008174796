<template>
  <div :class="classes">
    <label :for="id" :class="toggleClasses" :disabled="disabled">
      <input
        type="checkbox"
        :name="name"
        :value="optionValue"
        v-model="displayValue"
        :id="id || name"
        :required="required"
        :disabled="disabled"
      />
      <span v-if="toggle" class="toggle-container" :class="{'toggle-success': toggleSuccess}">
        <span v-if="toggleSize === 'md'" class="btn btn-sm" :class="{inactive: !value}">{{ trueText }}</span>
        <span v-if="toggleSize === 'md'" class="btn btn-sm" :class="{inactive: value}">{{ falseText }}</span>
      </span>
      {{ hideLabelText ? '' : label }}

      <slot name="tooltip" />
    </label>
  </div>
</template>

<script>
import inputMixin from "../utils/input-mixin.js";
export default {
  name: "EnInputCheckbox",
  mixins: [inputMixin],
  props: {
    value: {
      default: null,
    },
    optionValue: {
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    single: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: null
    },
    toggle: {
      type: Boolean,
      default: false
    },
    toggleSuccess: {
      type: Boolean,
      default: false
    },
    toggleSize: {
      type: String,
      default: "md"
    },
    trueText: {
      type: String,
      default: ''
    },
    falseText: {
      type: String,
      default: ''
    }
  },
  computed: {
    classes() {
      return {
        checkbox: (!this.inline && !this.toggle),
        "checkbox-inline": this.inline,
        "checkbox-single": this.single,
      };
    },
    toggleClasses() {
      if (this.toggle) {
        return `toggle toggle-${this.toggleSize}`
      }
      return ""
    },
    hideLabelText() {
      return this.toggle && this.toggleSize === 'md'
    }
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  &-single,
  &:first-child {
    margin-top: 0;
  }
}
</style>
