<template>
  <div class="en-options-dropdown" @keydown="handleKeydown" ref="dropdownContainer">
    <slot></slot>
    <div v-show="showInfoMessage" class="en-options-dropdown-container">
      <div class="en-options-dropdown-item">{{ infoMessage }}</div>
    </div>
    <transition name="fade">
      <div
        v-show="showDropdown"
        ref="dropdown"
        class="en-options-dropdown-container"
        tabindex="1"
      >
        <div v-if="textFilter" class="en-dropdown-item en-options-dropdown-filter-container">
          <input
            ref="filter"
            type="text"
            v-model="filterText"
            class="form-control en-options-dropdown-filter"
            placeholder="Filter"
            @focus="handleFilterFocus"
            @blur="handleFilterBlur"
          />
        </div>
        <div class="en-options-dropdown-container-items" ref="dropdownItems">
          <component
            v-if="options.length"
            v-for="(option, i) in filtered(options)"
            :is="option.group ? 'span' : 'a'"
            :href="option.group ? null : '#'"
            :key="i"
            ref="item"
            class="en-options-dropdown-item"
            :class="setItemClass(option, i)"
            :disabled="option.disabled"
            @mouseover="hoverItem = i"
            @mouseleave="hoverItem = selectedItemIndex || -1"
            @click.prevent="selectionHandler(option, i)"
            tabindex="-1"
            :data-automation-select="singleSelector(option)"
            :data-automation-multiselect="multiSelector(option)"
          >
            <slot name="option" :option="option" :index="i">{{
              formatter(option)
            }}</slot>
          </component>
          <option 
            v-if="!options.length"
            value=""
            class="en-options-dropdown-item"
          >
            No options available
          </option>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

export default {
  name: "EnOptionsDropdown",
  props: {
    selectedItems: {
      type: [String, Array, Number],
      default() {
        return [];
      }
    },
    options: {
      type: Array
    },
    formatter: {
      type: Function,
      default(option) {
        return option.label;
      }
    },
    infoMessage: {
      type: String,
      default: ""
    },
    showDropdown: {
      type: Boolean,
      default: false
    },
    arrowNavigation: {
      type: Boolean,
      default: true
    },
    textFilter: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      hoverItem: -1,
      filterText: "",
      filterIsFocused: false,
      lastSelection: -1,
      popper: null
    };
  },
  mounted() {
    this.popper = createPopper(this.$refs.dropdownContainer, this.$refs.dropdown, {
      placement: 'bottom',
      // strategy: 'fixed',
      modifiers: [
        // {
        //   name: 'preventOverflow',
        //   options: {
        //     rootBoundary: 'document',
        //     padding: 15,
        //   },
        // },
        {
          name: 'offset',
          options: {
            offset: ['0', '0'],
          },
        },
      ],
    })
  },
  computed: {
    showInfoMessage() {
      return this.infoMessage.length > 0;
    },
    multiSelect() {
      return typeof this.selectedItems !== "string";
    },
    selectedItemIndex() {
      if (!this.multiSelect) {
        return this.options.findIndex(
          item => item.value == this.selectedItems
        );
      }
      return -1;
    }
  },
  watch: {
    showDropdown(isOpen) {
      if (isOpen) {
        this.popper.update()
        this.hoverItem = -1;
        document.addEventListener("click", this.handleOutsideClick);
        document.addEventListener("focus", this.handleOutsideClick);
        if (this.textFilter) {
          this.$refs.filter.focus()
        }
      } else {
        this.filterText = ''
        document.removeEventListener("click", this.handleOutsideClick);
        document.removeEventListener("focus", this.handleOutsideClick);
      }
    }
  },
  methods: {
    singleSelector(option) {
      return option.group || this.multiSelect ? null : `${this.name}-${option.value }`
    },
    multiSelector(option) {
      return option.group || !this.multiSelect ? null : `${this.name}-${option.value }`
    },
    selectionHandler(option, i) {
      this.lastSelection = i
      const filterWasFocused = this.filterIsFocused
      this.$emit("select", option);
      if (this.multiSelect && filterWasFocused) {
        this.$refs.filter.focus()
        this.$refs.filter.select()
      }
    },
    setItemClass(option, index) {
      const classes = [];
      if (option.group) {
        return classes
      }

      if (index === this.hoverItem) {
        classes.push("-hovered");
      }
      // not sure why this condition?
      if (this.selectedItems == option.value) {
        classes.push("-selected");
        return classes;
      }
      if (isNaN(this.selectedItems) && this.selectedItems.indexOf(option.value) !== -1) {
        classes.push("-selected");
      }
      return classes;
    },
    handleKeydown(e) {
      if (!this.showDropdown) {
        return;
      }

      if (e.key === "Escape" || e.keyCode === 27) {
        this.$emit("close");
        return;
      }

      if (this.filterIsFocused) {
        if (e.key === "Tab") {

        }

      }

      if (!isNaN(this.lastSelection) && this.lastSelection !== -1) {
        this.hoverItem = this.lastSelection
        this.lastSelection = -1
      }

      const selectionKeys = ["Enter", "Tab", " "];
      if (
        this.showDropdown &&
        selectionKeys.includes(e.key) &&
        this.hoverItem > -1
      ) {
        e.preventDefault();
        this.selectionHandler(this.filtered(this.options)[this.hoverItem]);
        // right arrow key code, select suggestion, clear results, re-fetch
      } else if (e.key === "ArrowRight" || e.keyCode === 39) {
      } else if (e.key === "ArrowUp" || e.keyCode === 38) {
        e.preventDefault();
        this.changeOptionFocus('up')
        // down arrow key code
      } else if (e.key === "ArrowDown" || e.keyCode === 40) {
        e.preventDefault();
        this.changeOptionFocus('down')

      }
    },
    /**
     * method for changing the higlighted/focused option in the dropdown
     */
    changeOptionFocus(direction) {
      const numberOfItems = this.$refs.item.length
      if (numberOfItems === 0) {
        this.hoverItem = -1
        return
      }
      if (this.hoverItem === -1 && this.selectedItemIndex > -1) {
        this.hoverItem = this.selectedItemIndex
      } else if (this.hoverItem >= numberOfItems) {
        this.hoverItem = numberOfItems - 1
      } else if (direction === 'up') {
        this.hoverItem =
          this.hoverItem === 0
            ? numberOfItems - 1
            : (this.hoverItem -= 1);
      } else if (direction === 'down') {
        const isLast = this.hoverItem === numberOfItems - 1
        if (isLast) {
          this.hoverItem = 0
        } else {
          this.hoverItem += 1
        }
      }

      this.scrollOptionsWithhoverItem();
    },
    scrollOptionsWithhoverItem() {

      const results = this.$refs.dropdownItems,
        resultsHeight = results.offsetHeight,
        scrollTop = results.scrollTop,
        scrollPos = scrollTop + resultsHeight,
        activeItem = this.$refs.item[this.hoverItem],
        itemHeight = activeItem.offsetHeight,
        offsetTop = activeItem.offsetTop - results.offsetTop,
        offsetBottom = offsetTop + itemHeight;

      if (offsetBottom > scrollPos) {
        results.scrollTop = offsetBottom - resultsHeight;
      } else if (offsetTop < scrollTop) {
        results.scrollTop = offsetTop;
      }
    },
    handleOutsideClick(e) {
      e.stopPropagation();
      const target = e.target;

      if (!this.$el.contains(target)) {
        this.$emit("close");
      }
    },
    handleFilterFocus() {
      this.filterIsFocused = true;
    },
    handleFilterBlur(e) {
      this.filterIsFocused = false;
      const target = e.target;
      if (!this.$el.contains(target)) {
        this.$emit("close");
      }
    },
    filtered(options) {
      if (!this.textFilter || this.filterText === "") {
        return options;
      }
      return options.filter(option => {
        const textQuery = this.filterText.toLowerCase();
        if (typeof option === 'string') {
          return option.toLowerCase().includes(textQuery)
        } else if (Object.keys(option).length) {
          for (const key of Object.keys(option)) {
            if (typeof option[key] === 'string' && option[key].toLowerCase().includes(textQuery) || option.group ) {
              return true;
            }
          }
        } else {
          console.error('Options format is not able to be filtered.')
        }
        return false;
      });
    }
  }
};
</script>

<style lang="scss">
.en-options-dropdown {
  position: relative;

  &-container {
    position: absolute;
    // top: 100%;
    z-index: 5;
    background: white;
    box-shadow: 0 2px 8px rgba(#000, 0.2);
    width: 100%;

    &-items {
      max-height: 300px;
      overflow: auto;
    }

    @include focus-ring;
    &:focus-within,
    &:focus-visible {
      outline: 3px solid $focus-color;
      outline-offset: 0px;
      border-color: color(white);
    }
  }

  &-item {
    display: block;
    padding: 6px 12px;
    text-decoration: none;
    color: inherit;
    outline: 1px solid transparent;
    transition: none;


    @include hover {
      text-decoration: none;
      color: unset;
    }

    &.-hovered {
      background-color: $primary-color;
      color: color(white);
      outline: 1px solid $primary-color;
    }

    &.-selected {
      background-color: $primary-color-dark;
      color: color(white);

      &[disabled] {
        color: color(gray, 100);
      }
    }
  }
  span.en-options-dropdown-item {
    pointer-events: none;
    background-color: var(--gray-50);
    @include rem(font-size, 15px);
    @include font-weight(600);
  }

  &-filter {
    position: absolute;
    border-radius: 0;

    &-container {
      + .en-options-dropdown-container-items {
        margin-top: 42px;
      }
    }
  }


  .fade-enter-active, .fade-leave-active {
    transition: .2s opacity;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
}
</style>
